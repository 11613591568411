'use client';

import { IFormProps } from '@frontend/jetlend-core/src/models/form';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import InlineTextInput from '@frontend/jetlend-web-ui/src/ui/inputs/InlineTextInput/InlineTextInput';
import React, { useCallback } from 'react';
import connectToForm from '@frontend/jetlend-core/src/ducks/connectToForm';
import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { trimEmailValue } from '@frontend/jetlend-core/src/utils/trimEmailValue';
import {
    IEmailFormValues,
    IRegistrationState,
} from '@app/models/common/registration';
import {
    commonRegistrationEmailFormHandler,
    commonRegistrationStateHandler,
} from '@app/ducks/common/registration';
import styles from './stepEmailForm.module.scss';
import { CLIENT_TYPE_LOGIN_URLS } from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';

export interface IProps {
    state: IRegistrationState;
}

type Props = IProps & IFormProps<IEmailFormValues>;

const StepPhoneForm: React.FC<Props> = props => {
    const [state] = useObjectHandler(commonRegistrationStateHandler);

    const sendEventAction = useActions(sendEvent);

    const didSkipClicked = useCallback(() => {
        sendEventAction(`${state?.clientType}--skip-email`);

        const redirectUri = state?.redirectUri;
        if (redirectUri) {
            document.location.href = redirectUri;
        } else {
            document.location.href = CLIENT_TYPE_LOGIN_URLS[state?.clientType];
        }
    }, [ sendEventAction, state ]);

    return (
        <>
            <div className="h-ta-center h-mb-30" data-testid="step-title">
                Введите свой E-mail
            </div>
            <div className={styles['container']}>
                <div className={styles['input']}>
                    <InlineTextInput
                        name="email"
                        label="E-mail"
                        inputType="email"
                        autoComplete="email"
                        normalize={trimEmailValue}
                        autoFocus
                        disabled={props.formSubmitting}
                        noMarginBottom
                    />
                </div>

                <Button
                    className={styles['start-button']}
                    textClassName={styles['start-button__text']}
                    buttonType="submit"
                    text="Войти"
                    noSpacing
                    width={170}
                    disabled={props.invalid || !props.dirty}
                    loading={props.formSubmitting}
                    onClick={props.handleSubmit}
                />
            </div>
            <div className={styles['footer']}>
                <Button
                    type="plain"
                    size="big"
                    text="Пропустить и войти"
                    onClick={didSkipClicked}
                />
            </div>
        </>
    );
};

export default connectToForm(StepPhoneForm, commonRegistrationEmailFormHandler) as React.FC<IProps>;
