/**
 * Удаляет пробелы в начале и в конце строки email.
 *
 * @param email - строка, представляющая собой адрес электронной почты.
 * @returns {string | undefined} - возвращает очищенный email или `undefined`, если входное значение было `undefined`.
 *
 * @example
 *
 * const email = "  example@example.com  ";
 * const trimmedEmail = trimEmailValue(email); // "example@example.com"
 *
 */
export const trimEmailValue = (email: string): string | undefined => email?.trim();