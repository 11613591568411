import useCollectionApiHandler from '@frontend/jetlend-core/src/hooks/useCollectionApiHandler';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useCallback } from 'react';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { useSelector } from 'react-redux';
import { loginViaExternalAuthFormHandler } from '@app/ducks/common/login';
import styles from './ExternalAuth.module.scss';
import { ClientType } from '@app/models/common/common';
import ExternalAuthButton from './ExternalAuthButton';
import { getExternalAuthData } from '@app/ducks/common/oauth';
import { ExternalAuthVariant } from '@app/models/common/oauth';
import { registerViaExternalAuthFormHandler } from '@app/ducks/common/registration';

type ExternalAuthType = 'login'|'register';

const TITLE_MAP: Record<ExternalAuthType, string> = {
    'login': 'Войдите с помощью',
    'register': 'Зарегистрируйтесь с помощью',
};

const TOOLTIP_PREFIX_MAP: Record<ExternalAuthType, string> = {
    'login': 'Войти с помощью сервиса',
    'register': 'Зарегистрироваться с помощью сервиса',
};

export interface IProps {
    /**
     * Тип клиента.
     */
    clientType: ClientType;
    /**
     * Тип экрана, где используется блок внешних сервисов.
     */
    type: ExternalAuthType;
    /**
     * Кастомизация заголовка блока.
     */
    title?: string;
    /**
     * Дополнительные стили блока.
     */
    className?: string;
}

/**
 * React компонент предоставляющий интерфейс для авторизации пользователя с помощью сторонних сервисов.
 */
export default function ExternalAuth({
    clientType,
    type,
    title,
    className,
}: IProps) {
    const [ data ] = useCollectionApiHandler(getExternalAuthData, clientType);

    const registerByExternalAuth = useActions(registerViaExternalAuthFormHandler.submit);
    const registerByExternalAuthValues = useSelector(registerViaExternalAuthFormHandler.formSubmittingValues);

    const loginByExternalAuth = useActions(loginViaExternalAuthFormHandler.submit);
    const loginByExternalAuthValues = useSelector(loginViaExternalAuthFormHandler.formSubmittingValues);

    const didAuthVariantClicked = useCallback((variant: ExternalAuthVariant) => {
        if (type === 'register') {
            registerByExternalAuth({
                auth_variant: variant,
            });
        } else {
            loginByExternalAuth({
                auth_variant: variant,
            });
        }
    }, [loginByExternalAuth, registerByExternalAuth, type]);

    if (!data || !data.length) {
        return null;
    }

    return (
        <div className={mergeClassNames([styles['external-auth'], className])}>
            <p className={styles['description']}>
                {title ?? TITLE_MAP[type]}
            </p>
            <div className={styles['buttons-wrapper']}>
                {data.map(variant => (
                    <ExternalAuthButton
                        key={variant.id}
                        variant={variant}
                        tooltipPrefix={TOOLTIP_PREFIX_MAP[type]}
                        onClick={didAuthVariantClicked}
                        loading={registerByExternalAuthValues?.auth_variant ?? loginByExternalAuthValues?.auth_variant}
                    />
                ))}
            </div>
        </div>
    );
}
